*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --secondary-nav-height: 48px;
  --page-section-max-width: 1500px;
}

html {
  -webkit-tap-highlight-color: #0000;
}
body {
  background: #f0f0f0;
  color: #333;
  font-family:
    var(--font-inter, 'Inter'), 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
body.modal-open,
body.bt-modal-open {
  overflow: hidden;
}
/* Remove outline for non-keyboard :focus */
*:focus:not(:focus-visible) {
  outline: none;
}
mark {
  font-weight: bold;
  color: inherit;
  background-color: transparent;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tw-btn {
    @apply mb-0 flex h-11 cursor-pointer touch-manipulation select-none items-center justify-center whitespace-nowrap rounded-none border-0 bg-slate-200 px-3.5 py-0 text-center align-middle font-inherit text-md font-normal leading-5 text-slate-800 no-underline;
  }

  .tw-btn-link {
    @apply m-0 h-auto cursor-pointer border-0 bg-transparent p-0 font-inherit text-base leading-5 text-lapiz-500;
  }

  .tw-btn-filter {
    @apply h-auto rounded-sm p-2 font-semibold leading-none;
  }

  .tw-btn-noStyling {
    @apply m-0 cursor-pointer border-0 bg-transparent p-0 font-inherit;
  }
}

/* Used for the specview table */
.shadow-table-cell-inner {
  box-shadow:
    rgb(204, 204, 204) 0px -1px 0px inset,
    rgb(204, 204, 204) 1px 0px 0px inset;
}

.shadow-table-cell-outer {
  box-shadow:
    rgb(204, 204, 204) 0px 1px 0px inset,
    rgb(204, 204, 204) 1px 0px 0px inset;
}

.shadow-table-cell-inner-right {
  box-shadow:
    rgb(204, 204, 204) 0px -1px 0px inset,
    rgb(204, 204, 204) 1px 0px 0px inset,
    rgb(204, 204, 204) -3px 0px inset;
}

.shadow-table-cell-outer-right {
  box-shadow:
    rgb(204, 204, 204) 0px 1px 0px inset,
    rgb(204, 204, 204) 1px 0px 0px inset,
    rgb(204, 204, 204) -3px 0px inset;
}

.shadow-table-cell-bottom {
  box-shadow:
    rgb(204, 204, 204) 0px 1px 0px inset,
    rgb(204, 204, 204) 1px 0px 0px inset,
    rgb(204, 204, 204) 0px -3px 0px inset;
}

/**
 * The following styles are used to hide the horizontal scrollbar in the specs view table and specs view table sticky header.
 * and are only applied to the specs view table and specs view table sticky header.
 */

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox, Safari and Chrome */
}
